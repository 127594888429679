.flightHeader .icon {
    width:0.8em;
    fill: #aaaaaa;
    margin-right: 0.4em;
    margin-bottom: 1px;
}

.flightInfo .icon {
    width:0.8em;
    fill: #aaaaaa;
    margin-right: 0.4em;
}


.flightInfo {
    height:100%;
    text-align: left;
}

.flightInfo hr {
    margin:.3em 0px
}