* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans';
}

.paragraph p,
.paragraph h2,
.paragraph h3
{
    margin-top:1em;
}

.small-label {
    font-size:.7em;
    text-transform:uppercase;
    color:#757575;
    padding-right:.5em;
}